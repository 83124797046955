@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
@import "../form/Input";

.date-selector-input {
	&__date-selector {
		position: relative;
	}

	.control-group {
		&__input {
			padding-top: 5px; // pour separer un peu plus la date et le libellé
		}

		&--required {
			.control-group__label {
				&::after {
					content: "*";
				}
			}
		}
	}

	&__close {
		position: absolute;
		top: 15px;
		right: 15px;
		cursor: pointer;

		.icon {
			width: 15px;
			height: 15px;
		}
	}

	&__popper {
		background: white;
		border: 1px solid $grey-medium;
		border-radius: $border-radius-big;
		box-shadow: 0 3px 6px #6c6c6c29;
		z-index: 5;
		@include gridle_state(md) {
			padding: 0;
			margin: 0 15px;
		}

		&-arrow {
			stroke: $grey-medium;
		}
	}
}
