@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
.number-input {
	display: flex;
	align-items: center;

	&__input {
		@extend %font-bold;
		border: none;
		outline: 0;
		box-shadow: none;
		width: 30px;
		height: 30px;
		text-align: center;
		font-size: 1.4rem;
		line-height: 1.4rem;
		color: $black;
		background: unset;
		margin: 0 10px;
		opacity: 1; // chiffres pas visible sur ios safari a cause d'une opacity: 0.4 pour input["disable"]
		padding: 0; // SAFARI ajoute des padding qui déforme un input de forme ronde

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			appearance: none;
			margin: 0;
		}

		@include gridle_state(md) {
			width: 25px;
			height: 25px;
		}
	}

	&__button {
		border: none;
		outline: 0;
		box-shadow: none;
		background: $black;
		box-sizing: border-box;
		border-radius: 50%;
		width: 30px;
		height: 30px;
		color: white;
		font-size: 1.2rem;
		line-height: 1.2rem;
		cursor: pointer;
		appearance: none;

		@include gridle_state(md) {
			width: 25px;
			height: 25px;
			font-size: 1.6rem;
			line-height: 1.6rem;
		}

		&--disabled {
			pointer-events: none;
			background: $grey-medium;
			cursor: default;
		}
	}
}
