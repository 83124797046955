@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
/**
 * overflow-y: hidden cam make some header and footer on side panel disappear on ios
 * you have to put transform: translate3d(0, 0, 0); on both to avoid the issue
 */
.side-panel {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 10;
	overflow-y: hidden; // pour empecher le scroll bouncing sur ios et android @see https://www.smashingmagazine.com/2018/08/scroll-bouncing-websites/
	height: 100%;
	overscroll-behavior: contain;

}
